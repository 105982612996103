import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CreateFormGroupArgs, SaveEvent } from '@progress/kendo-angular-grid';
import { FormularioDevelopTabSeparadorSettings } from '../../config/formularios.develop.tabSeparador.settings';
import { ProcessType } from '@saludplus/forms';
import { formularioJSON } from '../../services/Formulario.store';

@Component({
    selector: 'tab-separador-formulario-develop',
    templateUrl: './tab-separador-formulario-develop.component.html',
    styleUrls: ['./tab-separador-formulario-develop.component.scss'],
    standalone: false
})
export class TabSeparadorFormularioDevelopComponent {

  // Forms - control de los estados
  processType: ProcessType = ProcessType.create;
  ProcessType = ProcessType;
  public formGroupSettingTabCampos: FormGroup;
  modeloEndPoint  
 

  @Output() dataTabsChange = new EventEmitter<any[]>();
 
  constructor( private formularioDevelopTabCamposSettings: FormularioDevelopTabSeparadorSettings,){
    this.formGroupSettingTabCampos = formularioDevelopTabCamposSettings.FormComponents()

    this.createFormGroup = this.createFormGroup.bind(this);
  }

  get JsonData(){
    return formularioJSON()
  }

  
  te(f) {
    console.log(f)
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    if (isNew) {
      setTimeout(() => {

        let first = this.JsonData.separadores[0];
       this.JsonData.separadores =this.JsonData.separadores.slice(1);


        
        first.tag = this.procesarString(first.nombre);
       this.JsonData.separadores.push(first)

       // this.dataTabsChange.emit(this.dataTabs);

        sender.closeRow(rowIndex);

        console.log(first)
      }, 150)
    }else{

     const current = structuredClone(this.JsonData.separadores[rowIndex]);
      
      setTimeout(() => { 
        const nuevoSeparador = this.procesarString(this.JsonData.separadores[rowIndex].nombre)

         
        this.JsonData.campos.filter(s=>s.separador == current.tag).forEach(element => {
         
        element.separador = nuevoSeparador
      });

     this.JsonData.separadores[rowIndex].tag = nuevoSeparador
    }, 150)
    }

    
  }

  procesarString(input: string): string {
    const cleanString = input
    .normalize("NFD") // Normaliza los caracteres Unicode con diacríticos en una secuencia de caracteres simple
    .replace(/[\u0300-\u036f]/g, "") // Elimina los diacríticos combinados
    .replace(/[^\w\s]|_/gi, "") // Elimina caracteres especiales y guion bajo
    .replace(/\s+/g, ''); // Elimina los espacios en blanco
 
  return cleanString;
}

  public createFormGroup(args: CreateFormGroupArgs): FormGroup {

    const formGroupSettingTabCampos = this.formularioDevelopTabCamposSettings.FormComponents(args)
    this.formGroupSettingTabCampos = formGroupSettingTabCampos;

    return this.formGroupSettingTabCampos;
  }
}
