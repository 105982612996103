import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProcessType } from '@saludplus/forms';
import { FormularioDevelopTabCamposSettings } from '../../config/formularios.develop.tabCampos.settings';
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { formularioJSON } from '@src/app/pages/developer/formularios-develop/services/Formulario.store';
import { Observable, catchError, of } from 'rxjs';
import { BuildFormulariosDevelopService } from '../../services/build-formulario.developer.service';
import { TegettFormControl, TypeField } from '@src/app/models/Tegett/tegettFormControl';

const requestMap = new Map<string, Observable<any[]>>();
const request = new Map<string, boolean>();

@Component({
    selector: 'campo-formulario-develop',
    templateUrl: './campo-formulario-develop.component.html',
    styleUrls: ['./campo-formulario-develop.component.scss'],
    standalone: false
})
export class CampoFormularioDevelopComponent extends SplusBaseForm implements OnInit, OnChanges {

  windowOpened = false;
  windowOpenedCampo = false;
  public override FormRecord: FormGroup;
  public FormRecordEspecialidades: FormGroup;
  public formRecordPreview: TegettFormControl;

  @Output() dataChange = new EventEmitter<any[]>();
  @Input() dataCampo: any;
  nameControl = ''

  dataModel: any = {}
  constructor(  
    public buildFormulariosDevelopService: BuildFormulariosDevelopService,
    private formularioDevelopTabCamposSettings: FormularioDevelopTabCamposSettings,) {

    super()

    this.controlProcess();

    this.processType = ProcessType.edit
  }

  get JsonData() {
    return formularioJSON()
  }

  ngOnInit(): void {

    this.nameControl = this.dataCampo.nameControl;

    let index = this.JsonData.campos.findIndex(s => s.nameControl == this.nameControl)
    this.dataModel = this.JsonData.campos[index]['properties'];

    //this.FormRecord.get('dataTextField').setValue('Nuevo valor');
  }


  public get GetDataCampo(){
    return this.JsonData.campos.find(s => s.nameControl == this.dataCampo.nameControl)
  }

 
  ResetPropertiesType(ControlPropertiesClear, value) {
    if (this.FormRecord) {
      ControlPropertiesClear.forEach(property => {
        if (this.FormRecord.controls[property]) {
          this.FormRecord.controls[property].reset({ value: value, disabled: false });
        }
      });
    }
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataCampo']) {

      this.FormRecord = null
      this.FormRecord = this.formularioDevelopTabCamposSettings.FormComponents(this.dataCampo);

      this.FormRecordEspecialidades = this.formularioDevelopTabCamposSettings.FormEspecialidadesComponents();

      switch (this.dataCampo.formato) {
        case 'string':
          this.icon = 'fa-duotone fa-input-text'
          break;
        case 'integer|int32':
          this.icon = 'fa-duotone fa-input-numeric'
          break;
        case 'integer|int64':
          this.icon = 'fa-duotone fa-input-numeric'
          break;
        case 'boolean':
          this.icon = 'fa-duotone fa-square-check'
          break;
        default:
          break;
      }
      this.VistaPrevia()
    }
  }

  VistaPrevia() {

    this.formRecordPreview = new TegettFormControl();

    if (this.JsonData && this.JsonData.campos) {

      let nuevoControl;

      const defaultValue = this.f['defaultValue']?.value;
      const label = this.f['label']?.value;
      const required = this.f['required']?.value;
      const nameControl = this.f['nameControl']?.value;
      const tipo = this.f['tipo']?.value;
      const maxDate = this.f['maxDate']?.value;
      const minDate = this.f['minDate']?.value;

      const properties = this.dataCampo?.properties;

      let formState = defaultValue != undefined && defaultValue != null ? defaultValue : ''

      switch (tipo) {
        case "Input":
          nuevoControl = new TegettFormControl({
            label: label,
            typeField: TypeField.Input,
            required: required,
            nameControl: nameControl,
            formState: formState
          })
          break;

        case "Date":
          nuevoControl = new TegettFormControl({
            label: label,
            typeField: TypeField.Date,
            required: required,
            nameControl: nameControl,
            formState: formState == 'fecha_actual' ? new Date() : formState,
          });

          if (maxDate && maxDate == 'fecha_actual') {
            nuevoControl['maxDate'] = new Date()
          }

          if (minDate && minDate == 'fecha_actual') {
            nuevoControl['minDate'] = new Date()
          }

          break;


        case "CheckBox":
          nuevoControl = new TegettFormControl({
            label: label,
            typeField: TypeField.CheckBox,
            required: required,
            nameControl: nameControl,
            formState: formState
          })
          break;

        case "List":
          nuevoControl = new TegettFormControl({
            label: label,
            typeField: TypeField.List,
            required: required,
            nameControl:nameControl,
            formState: formState
          })
          break;


        default:

          break;
      }

      if (nuevoControl) {

        nuevoControl['properties'] = properties;
        this.formRecordPreview = nuevoControl;

      }

      console.log(nuevoControl)


      setTimeout(() => {
        this.isLoading = false;
      }, 1000);

    }
  }


  ApiChange(evt) {
    this.dataModel = evt
    this.windowOpened = false
  }



  public GetDataApi(nameControl: string): Observable<any[]> {

    let properties = this.GetProperty(nameControl, 'properties')
    if (properties) {
      // Verificar si la petición ya se realizó
      if (!request.get(nameControl)) {
        const requestObservable = this.buildFormulariosDevelopService.GetDataSelect<any[]>(properties.ambiente, properties.endPoint).pipe(
          catchError(error => {
            console.error('Error en GetDataSelect:', error);
            return of([]);
          })
        );
        requestMap.set(nameControl, requestObservable);
        // Marcar la petición como realizada
        request.set(nameControl, true);
      }
      // Devolver el observable almacenado
      return requestMap.get(nameControl) as Observable<any[]>;
    }

    return of([]); // Retorna un Observable vacío
  }


  /**
   *
   * @param nameControl nombre del control
   * @param property propiedad a leer
   * @param level nivel de lectura. si es vacio entonces leera en la raiz. usar : para el nivel del obj. padre:hijo:nieto
   * @returns valor de la propieda.
   */
  GetProperty(nameControl: string, property: string, level = '') {
    let campo = this.JsonData.campos.find(s => s.nameControl == nameControl);

    if (campo) {
      if (level) {
        let levels = level.split(':');
        let current = campo;

        for (let l of levels) {
          if (current[l]) {
            current = current[l];
          } else {
            return null; // Si algún nivel no existe, devolvemos null
          }
        }

        return current[property] ? current[property] : null;
      } else {
        return campo[property] ? campo[property] : null;
      }
    }

    return null;
  }

  async onSubmit(event) {

    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {

        let index = this.JsonData.campos.findIndex(s => s.nameControl == this.nameControl)

        this.JsonData.campos[index] = this.FormRecord.value;
        this.JsonData.campos[index]['properties'] = this.dataModel;

        this.nameControl = this.FormRecord.get('nameControl').value;


        this.messageProcess.title = "Confirmación";
        this.messageProcess.message = "Campo guardado exitosamente";
        this.messageProcess.type = "success";
        this.VistaPrevia();
        this.processType = ProcessType.view;

      } else {


      }
    } else {

    }

    this.submitted = false;
    this.isLoading = false;
  }

  SaveRecord(): void {
    throw new Error('Method not implemented.');
  }
  UpdateRecord(): void {
    throw new Error('Method not implemented.');
  }
  DeleteRecord(): void {
    throw new Error('Method not implemented.');
  }
  LoadRecord(Id: number): void {
    throw new Error('Method not implemented.');
  }
  goEdit(): void {
    this.processType = ProcessType.edit;

  }
  cancelEdit(): void {
    throw new Error('Method not implemented.');
  }
  clearForm(): void {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    this.submitted = false;
  }

  labelChange(event) {
    let value = this.procesarString(event);
    this.FormRecord.get('nameControl').setValue(value)
  }

  procesarString(input: string): string {
    const cleanString = input
      .normalize("NFD") // Normaliza los caracteres Unicode con diacríticos en una secuencia de caracteres simple
      .replace(/[\u0300-\u036f]/g, "") // Elimina los diacríticos combinados
      .replace(/[^\w\s]|_/gi, "") // Elimina caracteres especiales y guion bajo
      .replace(/\s+/g, ''); // Elimina los espacios en blanco

    return cleanString;
  }


  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }

  //especialidades
  public get f_e(): any {
    return this.FormRecordEspecialidades.controls;
  }


  controlProcess() {
    const params = this.util.decodeURLParams(this.router.url);
    const mode = params.mode;

    if (this.route.routeConfig.path === ":id") {
      if (mode === "e") {
        this.processType = ProcessType.edit;
      } else {
        this.processType = ProcessType.view;
      }
      this.showMessageProcess = mode === "i" || mode === "u";

      this.messageProcess.title = "Confirmación";
      this.messageProcess.message = "Formulario guardado exitosamente";
      this.messageProcess.type = "success";
      if (mode === "df") {
        this.processType = ProcessType.view;
        this.messageProcess.title = "Error";
        this.messageProcess.message =
          "No se puede eliminar el Formulario porque tiene información asociada";
        this.messageProcess.type = "error";
        this.showMessageProcess = true;
      }
    } else {
      if (mode === "d") {
        this.processType = ProcessType.create;
        this.messageProcess.title = "Confirmación";
        this.messageProcess.message = "Formulario Eliminado exitosamente";
        this.messageProcess.type = "success";

        this.showMessageProcess = true;
      }

      if (mode === "df") {
        this.processType = ProcessType.edit;
        this.messageProcess.title = "Error";
        this.messageProcess.message =
          "No se puede eliminar el Formulario porque tiene información asociada";
        this.messageProcess.type = "error";
        this.showMessageProcess = true;
      }
    }
  }



  LimpiarCampos() {
    this.JsonData.campos = [];
    //  this.dataChange.emit(this.dataCampos); yoko
  }




}
