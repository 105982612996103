import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CreateFormGroupArgs, SaveEvent } from '@progress/kendo-angular-grid';
import { FormularioDevelopTabCamposSettings } from '../../config/formularios.develop.tabCampos.settings';
import { ProcessType } from '@saludplus/forms';
import { formularioJSON } from '../../services/Formulario.store';

@Component({
    selector: 'tab-campos-formulario-develop',
    templateUrl: './tab-campos-formulario-develop.component.html',
    styleUrls: ['./tab-campos-formulario-develop.component.scss'],
    standalone: false
})
export class TabCamposFormularioDevelopComponent {

  // Forms - control de los estados
  processType: ProcessType = ProcessType.create;
  ProcessType = ProcessType;
  public formGroupSettingTabCampos: FormGroup;
  modeloEndPoint  
  @Output() dataTabsChange = new EventEmitter<any[]>();
 
 
  constructor( private formularioDevelopTabCamposSettings: FormularioDevelopTabCamposSettings,){

  }

  ngOnInit() {
        this.formGroupSettingTabCampos = this.formularioDevelopTabCamposSettings.FormComponents()

        this.createFormGroup = this.createFormGroup.bind(this);
  }

  get JsonData(){
    return formularioJSON()
  }

  @Output() DataEvent = new EventEmitter();

  
  NuevoCampo(){
    const newCampo = {
      "label": "Nuevo Campo",
      "nameControl": "nuevoCampo", 
      "separador": "separadorInformacionBasica",
      "tipo": "Input",
      "formato": "string"
    }; 

    formularioJSON.update((json)=>{

      json.campos.push(newCampo);

      return json
    });

    this.DataEvent.emit()
  }

 
  LimpiarCampos() {
    this.JsonData.campos = [];    
    this.DataEvent.emit()
  }

 
 
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {

    const formGroupSettingTabCampos = this.formularioDevelopTabCamposSettings.FormComponents(args)
    this.formGroupSettingTabCampos = formGroupSettingTabCampos;

    return this.formGroupSettingTabCampos;
  }
}
