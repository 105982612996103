import { AfterViewInit, Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { Title } from "@angular/platform-browser";
import { IntlService } from "@progress/kendo-angular-intl";
import {
  ProcessType,
  SPlusMoreOption,
  SPlusOption,
} from "@saludplus/forms";
import { TegettFormControl, TypeField } from "@src/app/models/Tegett/tegettFormControl";
import { formularioJSON } from "../../services/Formulario.store";
import { BuildFormulariosDevelopService } from "../../services/build-formulario.developer.service";
import { Observable, catchError, of } from "rxjs";


const requestMap = new Map<string, Observable<any[]>>();
const request = new Map<string, boolean>();

@Component({
    selector: "app-build-formularios-develop",
    templateUrl: "./build-formularios-develop.component.html",
    styleUrls: ["./build-formularios-develop.component.scss"],
    standalone: false
})
export class BuildFormulariosDevelopComponent extends SplusBaseForm implements AfterViewInit {

  //develop
  @Output() dataChange = new EventEmitter<any[]>();


  @Output() PaintEvent = new EventEmitter();


  AgregarModeloEnTabla() {
    this.PaintEvent.emit("Event emitted from child");

  }

  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/nomina/empleados/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearEmpleado",
      showIn: ProcessType.view,
      text: "Crear nuevo empleado",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarEmpleado",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    }]

  // Forms
  formRecord: FormGroup;


  constructor(
    public buildFormulariosDevelopService: BuildFormulariosDevelopService,
    private formBuilder: FormBuilder,
    public override intl: IntlService, 
    public override titleService: Title, 
  ) {
    super()
    this.formRecord = this.formBuilder.group({});

    this.isLoading = true
  }

  get JsonData() {
    return formularioJSON()
  }

  ngAfterViewInit(): void {
    this.Load();
  }

  ngOnInit(): void {

  }

  Load() {
    if (this.JsonData && this.JsonData.campos) {
      this.JsonData.campos.forEach(element => {

        let nuevoControl;
        let formState = element.defaultValue != undefined && element.defaultValue != null ? element.defaultValue : ''

        switch (element.tipo) {
          case "Input":
            nuevoControl = new TegettFormControl({
              label: element.label,
              typeField: TypeField.Input,
              required: element.required,
              nameControl: element.nameControl,
              formState: formState
            });

            
            if (element['lookup']) { 
              nuevoControl['lookup'] = element['lookup'];
            }

            break;

          case "Date":
            nuevoControl = new TegettFormControl({
              label: element.label,
              typeField: TypeField.Date,
              required: element.required,
              nameControl: element.nameControl,
              formState: formState == 'fecha_actual' ? new Date() : formState,
            });

            if (element['maxDate'] && element['maxDate'] == 'fecha_actual' ) {
              nuevoControl['maxDate'] = new Date()
            }

            if (element['minDate'] && element['minDate'] == 'fecha_actual' ) {
              nuevoControl['minDate'] = new Date()
            }

            break;


          case "CheckBox":
            nuevoControl = new TegettFormControl({
              label: element.label,
              typeField: TypeField.CheckBox,
              required: element.required,
              nameControl: element.nameControl,
              formState: formState
            })
            break;

          case "List":
            nuevoControl = new TegettFormControl({
              label: element.label,
              typeField: TypeField.List,
              required: element.required,
              nameControl: element.nameControl,
              formState: formState
            })
            break;


          default:
            break;
        }

        if (nuevoControl) {
          this.formRecord.addControl(element.nameControl, nuevoControl);
        }

        console.log(element)
      });



      setTimeout(() => {
        this.isLoading = false;
      }, 1000);

    }
  }


  /**
   *
   * @param nameControl nombre del control
   * @param property propiedad a leer
   * @param level nivel de lectura. si es vacio entonces leera en la raiz. usar : para el nivel del obj. padre:hijo:nieto
   * @returns valor de la propieda.
   */
  GetProperty(nameControl: string, property: string, level = '') {
    let campo = this.JsonData.campos.find(s => s.nameControl == nameControl);

    if (campo) {
      if (level) {
        let levels = level.split(':');
        let current = campo;

        for (let l of levels) {
          if (current[l]) {
            current = current[l];
          } else {
            return null; // Si algún nivel no existe, devolvemos null
          }
        }

        return current[property] ? current[property] : null;
      } else {
        return campo[property] ? campo[property] : null;
      }
    }

    return null;
  }

  /**
   * buscar un campo en el json principal
   * @param nameControl nombre del control
   * @returns todos las propiedades del campo
   */
  GetField(nameControl: string) {
    let campo = this.JsonData.campos.find(s => s.nameControl == nameControl);
    return campo;
  }



  public GetDataApi(nameControl: string): Observable<any[]> {
    let properties = this.GetProperty(nameControl, 'properties')
    if (properties) {
      // Verificar si la petición ya se realizó
      if (!request.get(nameControl)) {
        const requestObservable = this.buildFormulariosDevelopService.GetDataSelect<any[]>(properties.ambiente, properties.endPoint).pipe(
          catchError(error => {
            console.error('Error en GetDataSelect:', error);
            return of([]);
          })
        );
        requestMap.set(nameControl, requestObservable);
        // Marcar la petición como realizada
        request.set(nameControl, true);
      }
      // Devolver el observable almacenado
      return requestMap.get(nameControl) as Observable<any[]>;
    }

    return of([]); // Retorna un Observable vacío
  }

  public get f(): any {
    return this.formRecord.controls;
  }

  dialogEliminar() {

  }
  onSubmit(event: any): void {

  }

  SaveRecord(): void {

  }

  UpdateRecord(): void {

  }

  DeleteRecord(): void {

  }

  LoadRecord(Id: number): void {

  }

  goEdit(): void {

  }

  cancelEdit(): void {

  }

  clearForm(): void {

  }


  ngOnDestroy(): void {

  }


}



