import { AbstractControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, Inject } from "@angular/core";
import { PersistenceService } from "src/app/services/Utilities/data.persistence.service";
import { LocalStorageList } from "src/app/helpers/enums/enums.keys";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import {
  DateFilter,
  MultipleFilter,
  MultipleFilterData,
} from "@src/app/models/Tegett/SimpleFilterData";
import { TiposDocumentos } from "@src/app/models/SaludPlus/TiposDocumentos.model";
import { Ciudades } from "@src/app/models/SaludPlus/Ciudades.model";
import Validation from "@src/app/components/Core/directives/generals/validatorError";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { lastValueFrom } from "rxjs";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods } from "@src/app/models/base/filterModel"; 
import { FormulariosDevelopService } from "../services/formulario.developer.service";

@Component({
    selector: "app-developerSettings2",
    template: "",
    standalone: false
})
export class ShareInfraestructaApiSettings   {

  tiposDocumentos: MultipleFilter;


  constructor(
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(FormulariosDevelopService) private empleadoServices: FormulariosDevelopService
  ) {

  }
   
  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {
 
        // ambiente
        ambiente: new TegettFormControl({
          formState: '',
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Ambiente",
          typeField: TypeField.List, 
          dataTextField: "ambiente",
          dataValueField: "idAmbiente",
          nameControl: "ambiente",
          searchType: "ambientes",
        }),


        // modulos
        modulo: new TegettFormControl({
          formState: '',
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Api Modulo",
          typeField: TypeField.List, 
          dataTextField: "modulo",
          dataValueField: "idModulo",
          nameControl: "modulo",
          searchType: "modulos",
        }),


        // grupo
        grupo: new TegettFormControl({
          formState: '',
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Api Grupo",
          typeField: TypeField.List, 
          dataTextField: "grupo",
          dataValueField: "idGrupo",
          nameControl: "grupo",
          searchType: "grupos",
        }),

        // endPoint
        endPoint: new TegettFormControl({
          formState: "",
          validatorOrOpts: [],
          asyncValidator: undefined,
          label: "Api EndPoint",
          typeField: TypeField.Input, 
          nameControl: "endPoint",
        }),

      },
      []
    );

    return formRecord;
  }

  /*LoadDataControls() {
    return this.pacientesServices.CargarCombosEmpleado<CombosEmpleadoDTO>();
  }*/


  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}

export default class DeveloperValidation {
  static ValidarTextLimpio(control: AbstractControl) {
    return (control: AbstractControl): { [key: string]: any } | null => {

      const value: string = control.value;
      if (!value) {
        return null;
      }
      if (value.trim().length === 0 || /^\d/.test(value)) {
        return { 'invalidText': true };
      }
      return null;
    };
  }
}
